

import React from 'react'
import '../../styles/hero.css'

import heroDarkImg from '../../images/digilogo.png'

const Hero = ({theme}) => {
  return (
    <section className='hero__section'>
        <div className='container'>
            <div className='hero__wrapper'>
                <div className='hero__content'>
                    <div>
                        <h2><br></br><br></br>We are Creating Perfect</h2>
                        <h2>Digital Products To</h2>
                        <h2 className='highlight'>Promote Your Brand</h2>
                    </div>
                    <p className='description'>Experience perfection with our digital agency's expertly crafted digital products. Captivate your audience with stunning web and app development, captivating graphic & video editing, and data-driven marketing strategies. Unlock your brand's full potential in the digital landscape. Partner with us for unparalleled success and elevate your online presence today!</p>

                </div>
                <div className='hero_img'>
                    <img src={theme=='light-theme' ? heroDarkImg : heroDarkImg} alt='logo' />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Hero;
