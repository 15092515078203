import React from 'react'
import '../../styles/about.css'

const About = () => {
  return (
   <section id='about'>
       <div className='container'>
           <div className='about__wrapper'>
               <div className='about__content'>
               <h6 className='subtitle'>Why Choose Us</h6>
                <h2>Exceptional Expertise and Client-focused Results.</h2>
                <h2 className='highlight'>Expert solutions, exceptional results, client-focused service. UpSwing Your success with us.</h2>
                <p className='description about__content-desc'>Discover the limitless possibilities of tomorrow with our pioneering digital agency. We specialize in cutting-edge AI-related products, dynamic app and web development, and captivating graphic & video editing services. Our data-driven digital marketing strategies elevate your brand, ensuring unparalleled growth and visibility. Tailored solutions meet your unique business needs, propelling you ahead in the dynamic digital world. Embrace success and stand out in the competitive landscape with us as your trusted partner. Unleash the power of innovation and elevate your brand to new heights with our expert team by your side!</p>
               </div>
           </div>
       </div>
   </section>
  )
}

export default About
