
import React from 'react'
import '../../styles/blog.css'



import aiImg from '../../images/ai.png'
import arvr from '../../images/arvr.png'
import blc from '../../images/blc.png'


const blogData =[
    {
        imgUrl: aiImg,
        title: 'AI Revolution',
        desc: 'AI, the cutting-edge technology, empowers machines to simulate human intelligence, solving complex tasks, and revolutionizing various industries.',
        linkUrl: '#'
    },

    {
        imgUrl: blc,
        title: 'Blockchain',
        desc: 'Blockchain: Revolutionizing Trust and Security. Discover how this transformative technology is reshaping industries and enhancing data integrity and transparency.',
        linkUrl: '#'
    },

    {
        imgUrl: arvr,
        title: 'AR/VR Insights',
        desc: 'AR/VR: Unveiling Immersive Experiences. Explore the fascinating world of Augmented and Virtual Reality and their applications across industries.',
        linkUrl: '#'
    }, 
]

const Blog = () => {
  return (
    <section id='blog'>
        <div className='container'>
            <div className='blog__top-content'>
                <h6 className='subtitle'>Our Blog</h6>
                <h2>
                    Let's have a look from our
                    <span className='highlight'> recent blog</span>
                </h2>
            </div>

            <div className='blog__wrapper'>
               {
                   blogData.map((item, index) =>(
                    <div className='blog__item' key={index}>
                    <h2>{item.title}</h2>
                    <div className='blog__img'>
                        <img src={item.imgUrl} alt='' />
                    </div>
                    <p className='description'>{item.desc}</p>
                    <div>
                        <a href={item.linkUrl} className='learn__more'><i class='ri-arrow-right-line'></i></a>
                        
                    </div>
                </div>
                   ))
               }
            </div>
        </div>
    </section>
  )
}

export default Blog
