import React from 'react';
import '../../styles/whatsapp.css';

const WhatsAppButton = () => {
  const whatsappUrl = `https://wa.me/7250383549?text=Hi%20there, Hey there! I came across your digital agency and I'm really interested in your services. Let's discuss further!`;

  return (
    <button className='whatsapp__btn' onClick={() => window.open(whatsappUrl, '_blank')}>
      WhatsApp
    </button>
  );
};

export default WhatsAppButton;
