import React from 'react'
import '../../styles/footer.css'

const Footer = () => {
  return (
   <footer className='footer'>
       <div className='container'>
           <div className='footer__wrapper'>
               <div className='footer__logo'>
                   <h2>Digi UpSwing</h2>
                   <p className='description'>Up Swing Your Success</p>
                   <p className='small__text description'>Digi UpSwing! Here creativity meets technical expertise. We are an innovative digital agency, offering web and app development, AI-driven products, graphic & video editing and data-driven digital marketing. Our tailored solutions empower businesses to thrive in the digital landscape. Elevate your brand with us and unlock unparalleled success! <br></br><br></br>
 Email: digiupswing@gmail.com | Phone: +91-7250383549 | Sector 34 - Noida <br></br>
© 2023 All rights reserved.</p>
               </div>
           </div>
       </div>
   </footer>
  )
}

export default Footer
