import React from 'react'
import '../../styles/services.css'

const serviceData = [

    {
        icon: 'ri-code-s-slash-line',
        title: 'Design & Development',
        desc: 'We offer a wide range of services, including Web Design, App Development, Graphic Design, UX/UI Design, Software Development. Our expertise empowers businesses to thrive in the digital world.'
    },

    
    {
        icon: 'ri-code-s-slash-line',
        title: 'Artificial Intelligence',
        desc: 'Our AI development expertise includes ML algorithms, NLP, computer vision, and chatbots, enabling businesses to achieve efficiency and innovation through automation and data-driven insights.'
    },

    {
        icon: 'ri-rocket-line',
        title: 'Digital Marketing',
        desc: 'Our comprehensive digital marketing services encompass SEO, social media, PPC, content marketing, and analytics, driving online visibility, engagement, and measurable growth for businesses across diverse industries.'
    },

    {
        icon: 'ri-apps-line',
        title: 'Ads',
        desc: 'Our comprehensive ad services cover Google Ads, Facebook, Instagram, LinkedIn, YouTube, Twitter, Amazon Ads, and Shopify Ads. With data-driven strategies, we optimize campaigns for increased sales and brand growth.'
    },


    {
        icon: 'ri-landscape-line',
        title: 'Graphics & Video',
        desc: 'Our graphics and video editing services produce captivating visuals and compelling video edits. From stunning graphics to engaging videos, we expertly enhance your brand narrative, leaving a lasting impact on your audience.'
    },

   
]

const Services = () => {
  return (
    <section id='service'>
        <div className='container'>
            <div className='services__top-content'>
                <h6 className='subtitle'>Our Services</h6>
                <h2>Elevate your brand with our extraordinary services</h2>
                <h2 className='highlight'>Where Creativity Meets Technology, Empowering Your Success in the Digital Landscape!</h2> 
            </div>
            <div className='service__item-wrapper'>
                {
                    serviceData.map((item, index)=>(
                        <div className='services__item' key={index}>
                        <span className='service__icon'>
                            <i class={item.icon}></i>
                        </span>
                        <h3 className='service__title'>
                            {item.title}
                        </h3>
                        <p className='description'>{item.desc}</p>
                    </div>
                    ))
                }
              
            </div>
        </div>
    </section>
  )
}

export default Services
