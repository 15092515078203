import React from 'react';

import './App.css';


import Header from './components/Header/Header';
import Hero from './components/UI/Hero';
import Counter from './components/UI/Counter';
import Services from './components/UI/Services';
import About from './components/UI/About';
import Team  from './components/UI/Team';
import Blog from './components/UI/Blog';
import Newsletter from './components/UI/Newsletter';
import Footer from './components/UI/Footer';
import WhatsAppButton from './components/UI/WhatsAppButton';
import Aiblog from './components/UI/Aiblog';

function App() {

  return (
   <>
   <Header />
   <Hero />
   <WhatsAppButton />
   <Counter />
   <Services />
   <About />
   <Team />
   <Blog />
   <Newsletter />
   <Footer />
   </>
  );
}

export default App;
