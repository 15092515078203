import React from 'react'
import '../../styles/team.css'
import deep from '../../images/deep.png'
import anu from '../../images/anur.png'
import nim from '../../images/nim.png'
import Anup from '../../images/anup.png'
import suhani from '../../images/suhani.png'
import neha from '../../images/neha.png'



const teamMembers = [
    {
        imgUrl: Anup,
        name:'Anup Shrivastava',
        position: 'Co-Founder & CEO',
        linkUrl: 'https://www.linkedin.com/company/digi-upswing/'
    },

    {
        imgUrl: deep,
        name:'Deependra Raj Amit',
        position: 'Co-Founder',
        linkUrl: 'https://www.linkedin.com/in/deependra-raj-amit-643226237/?originalSubdomain=in'
    },

    {
        imgUrl: neha,
        name:'Neha                  ',
        position: 'HR & Talent Management',
        linkUrl: 'https://www.linkedin.com/in/neha-61a7232a'
    },


    {
        imgUrl: anu,
        name:'Anurag Sharma',
        position: 'Sales & Marketing',
        linkUrl: 'https://www.linkedin.com/in/anurag-sharma-351a9a247'
    },

    {
        imgUrl: nim,
        name:'Nimalan R',
        position: 'Business Sales Strategist',
        linkUrl: 'https://www.linkedin.com/in/nimalan-r-a90150285'
    },

    
    {
        imgUrl: suhani,
        name:'Suhani Nalla',
        position: 'Artistic Strategy Manager',
        linkUrl: 'https://www.linkedin.com/in/suhani-nalla-119946250'
    },
]

const Team = () =>{
    return (
        <section className='our__team'>
            <div className='container'>
                <div className='team__content'>
                    <h6 className='subtitle'>Our Team</h6>
                    <h2>
                        Meet With <span className='highlight'>Our Team</span>
                    </h2>
                </div>
                <div className='team__wrapper'>
                   {teamMembers.map((item, index) => (
                        <div className='team__item' key={index}>
                        <div className='team__img'>
                            <img src={item.imgUrl} alt='' />
                        </div>
                        <div className='team__details'>
                            <h4>{item.name}</h4>
                            <p className='description'>{item.position}</p>
                            <div className='team__member-social'>
                            <span>
                            <a href={item.linkUrl} className='learn__more'><i class='ri-linkedin-line'></i></a>
                            </span>
                            </div>
                        </div>
                    </div>
                   ))}
                </div>
            </div>
        </section>
    )
}

export default Team;
