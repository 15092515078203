import React from 'react';
import '../../styles/newsletter.css';
import WhatsAppButton from './WhatsAppButton';

const Newsletter = () => {
  return (
    <section className='newsletter'>
        <div className='container'>
            <div className='newsletter__container'>
                <div className='newsletter__content'>
                    <h6 className='subtitle'>Let's Grow Your Business</h6>
                    <h2>
                        Explore the <span className='highlight'>hidden</span> ideas and Subscribe!
                    </h2>
                </div>

                <div className='newsletter__form'>
                    <input type='' placeholder='Have any querries! Feel free to ask us.' />
                    <WhatsAppButton />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Newsletter;
